<template>
  <div class="page-heading">
    Everything you need to drive your retail business.
  </div>

  <div class="outer">
    <div class="inner" v-for="d in data" :key="d.caption">
      <img :src="require('@/assets/pos/' + d.icon)" alt="" />
      <h3>{{ d.caption }}</h3>
      <p>{{ d.details }}</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "pos",
  mounted() {
    axios.get("data/pos.json").then((response) => {
      this.data = response.data;
    });
  },
  data() {
    return {
      data: [],
    };
  },
};
</script>

<style lang="scss" scoped>
.page-heading {
  font-size: 40px;
  padding: 40px 0;
  text-align: center;
}
.outer {
  background: lightgray;
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  .inner {
    img {
      width: 50%;
      height: auto;
    }
    h3 {
      font-size: 20px;
      color: blue;
      padding: 5px;
      margin-bottom: 10px;
    }
  }
}
/* .outer .inner {
} */
</style>
